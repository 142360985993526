"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = exports.AttachmentType = void 0;
var AttachmentType;
(function (AttachmentType) {
    AttachmentType["COMMENT"] = "COMMENT";
    AttachmentType["THREAD"] = "THREAD";
    AttachmentType["MESSAGE"] = "MESSAGE";
    AttachmentType["SERVER_BANNERS"] = "SERVER_CARDS";
    AttachmentType["SERVER_SECTIONS"] = "SERVER_SECTIONS";
})(AttachmentType || (exports.AttachmentType = AttachmentType = {}));
var FileType;
(function (FileType) {
    FileType["IMAGE"] = "IMAGE";
    FileType["AUDIO"] = "AUDIO";
    FileType["VIDEO"] = "VIDEO";
    FileType["GIF"] = "GIF";
    FileType["OTHER"] = "OTHER";
    FileType["LINK"] = "LINK";
})(FileType || (exports.FileType = FileType = {}));
