import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse, ApiResponsePagination } from "@/types/api";
import compact from "lodash/compact";
import { getQueryKey } from "@key.ai/constants";
import { cleanObject } from "@/lib/helper";

interface UserQueryProps {
  search?: string;
  conversationId?: string;
  serverId?: string;
}

export interface User {
  email: string;
  firstName: string;
  id: string;
  imageUrl: any;
  lastName: string;
  userId: string;
  inConversation?: boolean;
  displayName?: string;
}

export const useUsersQuery = ({ search, conversationId }: UserQueryProps) => {
  const fetchUsers = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data } = await clientApi
      .get(`api/v1/profile/users`, {
        searchParams: cleanObject({
          cursor: pageParam as string,
          search: search?.replace(/^@/, "") ?? search,
          conversationId
        })
      })
      .json<ApiResponse<ApiResponsePagination<User[]>>>();

    return data;
  };

  return useInfiniteQuery<ApiResponsePagination<User[]>>({
    queryKey: compact([getQueryKey("GLOBAL_USERS"), search, conversationId]),
    queryFn: fetchUsers,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    placeholderData: keepPreviousData
  });
};
