import React, { forwardRef } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { Text } from "@/components/ui/text";

type TooltipProps = {
  children: React.ReactElement;
  title?: string | React.ReactNode;
  description?: string;
  style?: any;
  theme?: string;
} & Partial<Omit<TippyProps, "content">>;

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  ({ children, title, description, theme, ...props }, ref) => {
    if (!title && !description) {
      return children;
    }

    return (
      <Tippy
        arrow={false}
        content={
          <div className="flex flex-col gap-[8px]">
            {title ? (
              <Text as="h3" className="text-[16px]">
                {title}
              </Text>
            ) : null}
            {description ? (
              <Text as="h5" className="text-[14px]">
                {description}
              </Text>
            ) : null}
          </div>
        }
        theme={theme ? theme : "key"}
        className="bg-white shadow-subtle-sm "
        touch={["hold", 500]}
        {...props}
      >
        {React.cloneElement(children, { ref })}
      </Tippy>
    );
  }
);

Tooltip.displayName = "Tooltip";
