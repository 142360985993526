"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberHiddenLinks = exports.MemberHiddenFields = void 0;
var MemberHiddenFields;
(function (MemberHiddenFields) {
    MemberHiddenFields["email"] = "email";
    MemberHiddenFields["phoneCode"] = "phoneCode";
    MemberHiddenFields["phoneNumber"] = "phoneNumber";
    MemberHiddenFields["pronouns"] = "pronouns";
})(MemberHiddenFields || (exports.MemberHiddenFields = MemberHiddenFields = {}));
var MemberHiddenLinks;
(function (MemberHiddenLinks) {
    MemberHiddenLinks["linkedin"] = "linkedin";
    MemberHiddenLinks["x"] = "x";
    MemberHiddenLinks["instagram"] = "instagram";
    MemberHiddenLinks["tiktok"] = "tiktok";
    MemberHiddenLinks["youtube"] = "youtube";
    MemberHiddenLinks["other"] = "other";
})(MemberHiddenLinks || (exports.MemberHiddenLinks = MemberHiddenLinks = {}));
